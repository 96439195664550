


*{
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  text-decoration: none;
  box-sizing: border-box;
}
input:active{
  background: transparent !important;
}
input:-internal-autofill-selected{
  background: transparent !important;
}

html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}
button{
  background-color: transparent;
  border: none;
}
body{
  background-color: #212026;
}



@import url('https://fonts.googleapis.com/css2?family=Cardo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;500;700&display=swap');