.LoadingBackground
{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  background:rgba(0, 0, 0, 0.37);
}
.LoaderContainer{
  margin: auto;
  color: #fff;
}
.MainFont{
  font-family: 'Cardo', serif;
  letter-spacing: -0.02em;
  font-weight: 400;
}
.SubFont{
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.0024em;
  font-weight: 500;
}
.MainFont-kr{
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500;
}

.SubFont-kr{
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: -0.0024em;
  font-weight: 300;
}

.RowSection{
  display: flex;
flex-direction: row;
margin-left: auto;
margin-right: auto;
overflow-y: hidden;
justify-content: space-between;
align-items: center;
position: relative;
margin: 6.5rem auto;
}

.SubTitle{
  font-size: 1rem;
  background: linear-gradient(90deg, #1400FF -200.63%, #00FFA3 90.72%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}
.Title{
  font-size: 3rem;
  color: #fff;
}

.SectionWidth{
  max-width: 81.25rem;
  padding: 0rem 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
.SectionDescription{
  color: #F2EFE8;
  font-size: 0.875rem;
}

.FirstSection{
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin: 4.8rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ThirdSection{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15rem;
  padding-bottom: 16.25rem;
  margin: 4.8rem auto;
}
.TitleSubtitleGroup{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.TitleDescGroup{
  display: flex;
  flex-direction: column;
  gap:1.5rem;
  width: 50%;
}
.AlignCenter{
  align-items: center;
}
.SecondSection{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin: 10rem auto;
}
#slider_egMAgweksq{
  gap: 20px;
}
.IconBackground{
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  justify-content: center;
  height: 100px;
  margin-right: 1.25rem;
}

.ThirdTitleGroup{
  text-align: center;
}
.ThirdSmallTitle{
  font-size: 1.875rem;
  color: #fff;
}

.TitleDescGroupThird{
  width: 66.666%;
  display: flex;
  flex-direction: column;
  gap:3rem;
}
.DescGroupThird{
  width: 100%;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.FourthSection{
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1.25rem;
  position: relative;
  height: 110vh;
  overflow-y: hidden;
}

.TitleDescGroupFourth{
  display: flex;
  flex-direction: column;
  gap:1.5rem;
  width: 72%;
}

.FlexEndAlignment{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 70%;
}
.Relative{
  position: relative
}
#weve_worked_with{
  position:absolute;
  top: -200px;
}
.TopBottomMargin{
  margin: 3rem 0;
}
.ColUl{
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  gap: 0.5rem;
  
}





.ImageContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  width: 56%;
  left: -240px;
  position: absolute;
}



.FloatingNav,.FloatingNavResp{
  position:fixed;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(5px);
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  bottom: 4rem;
  width: max-content;
}
.FloatingNavResp{
  display: none;
}

.FloatingNavResp .FloatingLogoBtn{
  cursor: pointer;
}
.FloatingNavResp .FloatingLogoBtn,.FloatingLogoBtn{
  background: rgba(0, 0, 0, 0.40);
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
}
.FloatingMenuBtn{
  padding: 0.75rem;
}
.FloatingMenuBtn a{
  color: #FFF;
  font-size: 0.8125rem;
  width: 100%;
}
.TalkBtn{
  border-radius: 16px;
  background: #01FFA4;
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
}
.TalkBtn a {
  color: #000;
}
.FloatingNav ul{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}

.FloatingMenuBtn .LanguageSelector{
  font-size: 0.8125rem;
}

.FormBackground{
  width: 100%; 
  display: flex;
  background: rgba(0, 0, 0, 0.37);
  border: none;
  border-radius: 16px;
  backdrop-filter: blur(5px);
  box-shadow: 12px 15px 54px 0px rgba(255, 255, 255, 0.02);
  padding: 3rem 2.5rem;
  flex-direction: column;
    gap: 3rem;
}
.FooterTitleDesc{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

input::placeholder, textarea::placeholder, select::placeholder, .Agree, a{
    color: #F2EFE8;
}

.inputBorder{
  border-bottom: 1px solid #f2efe8;
}

input, textarea, select{
    background: transparent;
    border: none;
    outline: none;
    color: #F2EFE8;
    font-size: 1rem;
    padding: 0.75rem 1rem ;
}
.react-international-phone-country-selector-dropdown:focus{
    outline: none;
}
.react-international-phone-country-selector-dropdown{
    position:absolute;
    top: 30%;
    background: #1C1B1F; 
    padding: 1rem;
    max-width: 380px;
    height: 200px;
    border: 1px solid #fefefe1a;
    border-radius: 16px;
}

.react-international-phone-country-selector-dropdown__list-item{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.react-international-phone-country-selector{
    background: transparent;
    border: none;
    outline: none;
    color: #F2EFE8;
    font-size: 1rem;
    padding: 0.5rem 1rem ;
}

.react-international-phone-input-container{
    display: flex;
    flex-direction: row;
    width: 100%;
}
select{
    width: 8rem;
    text-overflow: ellipsis;
}
option{
    display: flex;
}
.ContactFormBackground{
    width: 100%;
    margin:auto;
}
.ContactForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.FormRow{
    display: flex;
    flex-direction: row;
    gap: 1.24rem;
    width: 100%;
}
.FirstRowDetail{
    width: 50%;
}
.FirstRowDetail input, textarea{
    width: 100%;
}
textarea{
    height: 15rem;
}
.Phone{
    display: flex;
    flex-direction: row;
    gap: 0;
    width: 50%
}
.Phone select{
    width: 40%;
}
.Phone input[type="tel"]{
    width: 100%;
}

.SubjectSelector{
    width: 100%;
}
.Agree{
    font-size: 0.75rem;
}
.Agree a {
    text-decoration: underline;
}
.CheckboxAgree{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}
input[type="checkbox"]{
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #F2EFE8;
    cursor: pointer;
    margin: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    padding: 0.5rem; 
}
input[type="checkbox"]:checked{
    background-color: #000;
    position: relative;
}

input[type="checkbox"]:checked::before{
    content: url('./images/check_ico.svg');
    font-size: 1.5em;
    color: #000;
    position: absolute;
}
.SubmitButton{
    display: flex;
    padding: 0.75rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 100px;
    background: #01FFA4;
    width:fit-content;
    color: #000;
}

.FooterSection{
    border-top: 1px solid #969E9B;
    padding: 6rem 0;
    margin-top: 6rem;
    position: relative;
    height: max-content;
    overflow-y: hidden;
}
.FooterBackground{
    position: absolute;
    right: 0%;
    bottom: 20%;
    width: 50%;
    height: auto;
    z-index: -1;
}
.FooterBottom{
    position: relative;
    border-top: 1px solid #969E9B;
    background: #1C1B1F; 
    padding: 2.5rem 0;
    z-index: 999;
}
.FooterBand{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.FooterBand .SubFont{
    color: #F2EFE8;
    font-size: 0.8125rem;
}



.scene-container{
  position: relative;
width: 100% !important;
height: auto!important;
overflow-y: hidden !important;

}

canvas{
  width: 100% !important;
  height: 100% !important;
  right: 0% !important;
  position: relative;
  overflow-y: hidden !important;
  z-index: 0;
}



.HeroText {
  font-size: 3rem;
  color: #fff;
}
.HeroText-kr{
  font-size: 2.8rem;
}
.HerohighLight1{
  background: linear-gradient(120deg, #1400FF 2%, #00FFA3 70%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}

.HerohighLight2{
  background: linear-gradient(120deg, #1400FF, #01FFA4 48%  );
-webkit-text-fill-color: transparent;
background-clip: text;
}
.HeroTitleGroup{
width: 100%;
z-index: 1;
height: 100%;
display: flex;
flex-direction: column;
gap: 2.5rem
}
.HeroTitleRow{
  display: flex;
  flex-direction: row;
  gap: 2px
}

.HeroDescription{
  color: #F2EFE8;
  font-size: 1.25rem;
}
nav{
  max-width: 81.25rem;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 56px;
  padding: 0 1.25rem;
}

nav, ul, li{
  display: flex;
}

.NavLink{
  color: #fff;
  font-size: 1rem;
}

.HeaderNav a, .HeaderNav button{
  padding: 0.75rem 1rem;
}
.HeaderNav .DeepBridgeLogoStyle{
  padding:0.75rem 0rem;
}


.LanguageSelector{
  background : transparent;
  border: none;
  font-size: 1rem;
  color: #fff;
  padding: 0.875rem;
  width: 5rem;
}
.LanguageSelector:active, .LanguageSelector:focus,
.LanguageSelector:active option, .LanguageSelector:focus option{
  outline: none;
}

.SideBar{
  border-right: 1px solid #969E9B;
  position: fixed;
  left: 0;
  height: 100vh;
  padding: 0.5rem;
  z-index: 10;
  display: flex;
  mix-blend-mode: difference;
}
.AnchorSide{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
 
}
.AnchorSide img{
  mix-blend-mode: difference;
  
}

.ListWidth{
  width: 40%;
}
.ListCol{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  height: fit-content;
  overflow-y: hidden;
}
.ListTitle{
  font-size: 1.25rem;
  color: #ffffff;
}
.ListDescription{
  color: #ffffff;
  font-size: 0.875rem;
}
/* .ListDefault{
  display: flex;
  padding: 0.5rem 0.75rem;
  border-left: 1px solid transparent;
}

.ListDeco{
  display: flex;
  border-left: 1px solid #01FFA4;
  padding: 0.5rem 0.75rem;
} */
.ListDescAlign{
  padding: 0.25rem 1.5rem;
}

@media only screen and (max-width: 1400px){
  .SectionWidth{
    padding: 5rem 4.25rem;
  }
  .RowSection{
    margin-top: 1.5rem;
  }
  .FooterBottom .SectionWidth{
    padding: 0rem 4.25rem;
  }
  canvas{
    width:100% !important;
    height:100% !important;
    right: 0% !important;
  }
  nav{
    padding: 0 4.25rem;
    margin-top: 1.5rem;
  }
  }


@media only screen and (max-width: 1200px){
  .HeaderNav ul{
      display: none;
  }
}

@media only screen and (max-width: 920px){
  
  .HeroRowSection{
    display: flex;
    flex-wrap: wrap;
  }
  .FirstSection{
    flex-direction: column;
    gap: 5rem;
  }
  .TitleDescGroup,.FlexEndAlignment{
    width: 100%
  }
  .TitleDescGroupThird,.TitleDescGroupFourth{
    width: unset
  }
  .FourthSection{
    flex-direction: column-reverse;
    height:unset;
    padding: 0 4.25rem;
    margin: 5rem 0rem;
    gap: 5rem;
  }
  .Title{
    font-size: 2rem;
  }
  .ImageContainer{
    position: unset;
}
  .ListWidth{
    width: 100%;
}
  .FloatingNav{
    display: none;
}
.FloatingNavResp{
    display: flex;
}
.FloatingNavResp{
    flex-direction: column-reverse;
    gap: 1.25rem;
}
  .FloatingNavResp ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    display: none;
    width: 100%;
    gap: 0.5rem;
  }
  .FloatingNavResp ul li{
    width: 100%;
  }
  canvas{
      width:120% !important;
      height:30% !important;
      left: -10% !important;
      margin: auto;
  }
}


@media only screen and (max-width: 580px){
  .SideBar{
    display: none;
}
  nav{
    padding: 0 1rem;
}
  .FormRow{
    flex-direction: column;
}
.FirstRowDetail,.Phone{
    width: 100%;
}
.FooterBackground{
    width: 80%;
}
.FooterBottom{
    padding: 2rem 0;
}
  .ImageContainer{
    width: 100%;
}
  .SectionWidth{
    padding: 5rem 1rem;
  }
  .FourthSection{
    padding: 0 1rem;
  }
  .FooterBottom .SectionWidth{
    padding: 0rem 1rem;
  }

}